import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import { green, red } from '@mui/material/colors';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import Logo from '../logo.png';  // Import your logo image
import styled from '@emotion/styled';
import { Avatar, Container, IconButton, ListItemAvatar, Paper } from '@mui/material';
import FoodIcon from '../Shared/FoodIcon';
import VegIcon from '../v.png';
import NonVegIcon from '../nv.png';
// import NonVegFoodIcon from '../Shared/NonVegFoodIcon';
import data from '../items.json';
interface MenuItem {
    name: string;
    price: number;
    isVeg: boolean;
}

interface Category {
    name: string;
    items: MenuItem[];
}

const categories: Category[] = data;

const CustomAccordion = styled(Accordion)(({ theme }) => ({
    '&.MuiAccordion-root': {
        margin: '0.2rem 0',
        textAlign: 'center',
        '& .MuiAccordionSummary-content': {
            justifyContent: 'center',
        },
        '& .Mui-expanded': {
            border: '1px solid #28a745'
        }
    }
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    '&.MuiAccordionSummary-root': {
        backgroundColor: '#28a745',
        fontWeight: 'bold',
        color: '#fff',
        marginBottom: '0.1rem',
    },
    '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
        color: '#fff',
        fontWeight: 'bold',
        backgroundColor: '#28a745',
    }
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    '&.MuiAccordionDetails-root': {
    }
}));

// border-bottom: 1px solid #dfe0e4;




const MenuComponent: React.FC = () => {

    return (
        <Container maxWidth="md" sx={{ backgroundColor: '#fff' }}>
            <AppBar position="static" sx={{ backgroundColor: '#333', mx: 'auto', maxWidth: 'md' }}>
                <Toolbar>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', m: 3, width: '100%', justifyContent: 'center' }}>
                        <Typography align='center'><img src={Logo} alt="QR Menu" style={{ maxWidth: '100%', height: 'auto' }} /></Typography>
                        <Typography align='center' variant="subtitle1">School Line, Port Blair</Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            {categories.map((category, i) => (
                <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                    <CustomAccordion key={i} elevation={0}>
                        <CustomAccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${i}-content`}
                            id={`panel${i}-header`}
                        >
                            <Typography align="center" >{category.name}</Typography>
                        </CustomAccordionSummary>
                        <CustomAccordionDetails>
                            {category.items.map((item, j) => (
                                <ListItem key={j}
                                    secondaryAction={
                                        <Typography>{`₹${item.price.toFixed(2)}`}</Typography>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Box style={{ color: item.isVeg ? green[500] : red[500] }}>
                                            <img src={item.isVeg ? VegIcon : NonVegIcon} alt='' />
                                        </Box>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item.name}
                                    />
                                </ListItem>

                                //                                 <ListItem key={j}>
                                //                                     <ListItemIcon style={{ color: item.isVeg ? green[500] : red[500] }}>
                                //                                         
                                //                                     </ListItemIcon>
                                //                                     {' '}&nbsp;
                                //                                     <Box display="flex" justifyContent="space-between" width="100%">
                                //                                         <ListItemText primary={item.name} />
                                //                                         <Typography>{`₹${item.price.toFixed(2)}`}</Typography>
                                //                                     </Box>
                                //                                 </ListItem>
                            ))}
                        </CustomAccordionDetails>
                    </CustomAccordion >
                </Slide >
            ))}
        </Container >
    );
};

export default MenuComponent;
